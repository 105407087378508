<template>
	<SubPageListLayout :loading="activityStore.loading">
		<template #sub_bar>
			<div class="px-6 pt-3 pb-5 bg-gray-50">
				<form class="mt-2 flex space-x-4" action="#">
					<div class="min-w-0 flex-1">
						<label for="search" class="sr-only">Search</label>
						<div class="relative rounded-md shadow-sm">
							<div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
								<FontAwesomeIcon :icon="faMagnifyingGlass" aria-hidden="true" class="h-5 w-5 text-gray-400" />
							</div>
							<input id="search" type="search" name="search" class="block w-full rounded-md border border-gray-200 pl-10 py-2 focus:outline-0 sm:text-sm" placeholder="Search forms" />
						</div>
					</div>
				</form>
			</div>
			<hr />
			<div class="min-h-0 flex-1 overflow-y-auto">
				<div
					v-for="formRequests in activityStore.activity.form_requests as FormRequest[]"
					:key="formRequests.id"
					:class="[selectedFormRequest === formRequests.id ? 'bg-primary-50 bg-opacity-50 shadow-inner' : 'hover:bg-primary-50', 'first:pt-2 flex flex-col pt-3 px-3 pb-1 border-b border-blue-gray-200 cursor-pointer']"
					:aria-current="selectedFormRequest === formRequests.id ? 'page' : undefined"
					@click="() => selectFormRequest(formRequests.id)"
				>
					<div class="flex flex-row w-full pointer-events-none">
						<div class="ml-2 w-full flex flex-col relative">
							<p class="text-base font-bold text-black">{{ formRequests.form?.name }}</p>
							<p class="text-sm text-gray-900 font-bold">[{{ formRequests.form_key }}]</p>
							<p class="text-xs my-1 text-gray-900 italic">{{ formRequests.form?.description }}</p>
							<div class="flex flex-row w-full justify-between">
								<p class="text-xxs font-light text-gray-900">Created by {{ formRequests.creator.first_name }} {{ formRequests.creator.last_name }} {{ getRelativeFormatFrom(getLocalizedDate(formRequests.created_at)) }}</p>
								<p class="text-xxs font-light text-gray-900">Updated {{ getRelativeFormatFrom(getLocalizedDate(formRequests.updated_at)) }}</p>
							</div>
							<div class="absolute right-0 top-0">
								<span v-if="formRequests.status == FormRequestStatus.AWAITING_ACTIVATION" class="inline-flex rounded-full bg-red-400 px-2 text-xs font-semibold leading-5 text-white">{{ FormRequestStatusLabel[formRequests.status] }}</span>
								<span v-else-if="formRequests.status == FormRequestStatus.ACTIVATED" class="inline-flex rounded-full bg-blue-500 px-2 text-xs font-semibold leading-5 text-white">{{ FormRequestStatusLabel[formRequests.status] }}</span>
								<span v-else-if="formRequests.status == FormRequestStatus.IN_PROGRESS" class="inline-flex rounded-full bg-orange-500 px-2 text-xs font-semibold leading-5 text-gray-100">{{
									FormRequestStatusLabel[formRequests.status]
								}}</span>
								<span v-else-if="formRequests.status == FormRequestStatus.SUBMITTED" class="inline-flex rounded-full bg-green-400 px-2 text-xs font-semibold leading-5 text-gray-100">{{ FormRequestStatusLabel[formRequests.status] }}</span>
								<span v-else-if="formRequests.status == FormRequestStatus.CLOSED" class="inline-flex rounded-full bg-blue-500 px-2 text-xs font-semibold leading-5 text-gray-100">{{ FormRequestStatusLabel[formRequests.status] }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template #sub_content>
			<RouterView />
			<div v-if="route.name === 'activity.form'" class="flex flex-col h-full w-full">
				<div v-if="!selectedFormRequest" class="flex flex-col h-full w-full justify-center items-center overflow-y-auto">
					<div class="rounded-lg border-dashed border-2 h-72 flex flex-col justify-center items-center w-3/4 border-black">
						<FontAwesomeIcon :icon="faClipboardListCheck" aria-hidden="true" class="h-24 w-24" />
						<h1 class="select-none mt-6 font-medium text-2xl">To view a form, select it on the left!</h1>
					</div>
				</div>
			</div>
		</template>
	</SubPageListLayout>
</template>

<script lang="ts" setup>
	import { useActivityStore } from '@modules/activities/store';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faClipboardListCheck, faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
	import { FormRequestStatus, FormRequestStatusLabel } from '@modules/form/utils/constants';
	import { getLocalizedDate, getRelativeFormatFrom } from '@utils/useDate';
	import SubPageListLayout from '@layouts/SubPageListLayout.vue';
	import { ref, watch } from 'vue';
	import { FormRequest } from '@/types/graphql';
	import { useRoute, useRouter } from 'vue-router';

	const activityStore = useActivityStore();
	const route = useRoute();
	const router = useRouter();

	const selectedFormRequest = ref(route.params.form_request_id ?? undefined);

	/**
	 * Navigates to the route for displaying a specific form request within an activity.
	 *
	 * @param {string} formRequestId - The unique identifier of the form request to be displayed.
	 */
	const selectFormRequest = (formRequestId: string) => {
		router.push({
			name: 'activity.form.show',
			params: {
				activity_type: route.params.activity_type,
				activity_id: route.params.activity_id,
				form_request_id: formRequestId,
			},
		});
	};

	watch(
		() => route.params.form_request_id,
		(newFormRequestId) => {
			selectedFormRequest.value = newFormRequestId;
		},
	);
</script>
